@import '../helpers/variables';
@import '../mixins';

.cookie-settings button {
  background-color: transparent !important;
  border: 0 !important;
  color: $white !important;
  font-family: $font-family-copy !important;
  font-size: 16px !important;
  padding: 0 !important;
  text-decoration: underline;
  white-space: nowrap !important;
}

.footer {
  background-color: $white;
  border-top: 2px solid $grey;
  color: $navy-dark;
  padding: 45px 0 0;
  width: 100%;

  &-sub {
    align-items: center;
    display: flex;
    justify-content: center;

    @include max-tablet {
      flex-wrap: wrap;
    }

    &__item {
      margin: 5px 10px;
    }

    &__link {
      color: $white;
      white-space: nowrap;

      &:hover {
        color: $white;
      }
    }
  }

  &--extra-padding {
    padding: 45px 0 65px;

    @include min-tablet {
      padding: 45px 0;
    }
  }

  &-main__link {
    display: flex;
    justify-content: space-between;
  }

  &-cols {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

    &__col {
      padding-bottom: 20px;

      @include max-desktop {
        flex-basis: 45%;
      }
    }

    &__title {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    &__link {
      display: block;
      margin-bottom: 5px;
    }
  }

  [class^='fi-'] {
    display: inline-block;
    font-size: 20px;
    margin: 0 6px;

    @include only-desktop {
      margin: 0 3px;
    }
  }
}
