@import '../../mixins';
@import '../../helpers/variables';

.dark-theme {
  --slider-color-on: #{$orange};
  --slider-color-on-active: #{darken($orange, 10%)};
  --slider-color-off: #{darken($grey, 10%)};
  --slider-color-off-active: #{darken($grey, 35%)};
}

.switch {
  border-radius: 15px;
  cursor: pointer;
  display: inline-block;
  height: 30px;
  overflow: hidden;
  position: relative;
  width: 70px;

  input {
    height: 1px;
    opacity: 0;
  }
}

input:checked + .strip {
  background-color: var(--slider-color-on, $navy-dark);
  border-color: var(--slider-color-on, $navy-dark);
  transform: translateX(0);
}

.strip {
  background-color: var(--slider-color-off, darken($grey, 10%));
  display: flex;
  left: 0;
  padding: 2px;
  position: absolute;
  top: 0;
  transform: translateX(-40px);
  transition: all 0.5s;

  input:focus + & {
    background-color: var(--slider-color-off-active, darken($grey, 35%));
  }

  input:checked:focus + & {
    background-color: var(--slider-color-on-active, darken($navy-dark, 10%));
  }

  .on,
  .off {
    color: $white;
    display: inline-block;
    font-weight: bold;
    height: 100%;
    line-height: 26px;
    margin: 0 5px;
    text-align: center;
    vertical-align: super;
    width: 30px;
  }

  .pin {
    background-color: $white;
    border-radius: 50%;
    content: '';
    display: inline-block;
    height: 26px;
    left: 2px;
    top: 2px;
    transition: 0.4s;
    width: 26px;
  }
}
