@import '../helpers/variables';
@import '../mixins';

.dark-theme {
  --link-color: #{lighten($blue, 10%)};
  --link-color--active: #{$blue};
  --link-color--hover: #{darken($blue, 5%)};
}

// typography
@font-face {
  font-display: swap;
  font-family: 'Strada';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/Strada-Regular.eot');
  src: local('Strada-Regular'),
    url('../fonts/Strada-Regular.woff') format('woff'),
    url('../fonts/Strada-Regular.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Strada';
  font-style: normal;
  font-weight: bold;
  src: url('../fonts/Strada-Bold.eot');
  src: local('Strada-Bold'),
    url('../fonts/Strada-Bold.woff') format('woff'),
    url('../fonts/Strada-Bold.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'muli';
  font-style: normal;
  font-weight: 100 700;
  src: url('../fonts/muli/Mulish-VariableFont_wght.ttf') format('truetype-variations'),
    url('../fonts/muli/Mulish-Regular.ttf') format('truetype'); /* for older browsers */
}

@font-face {
  font-display: swap;
  font-family: 'muli';
  font-style: italic;
  font-weight: 100 700;
  src: url('../fonts/muli/Mulish-Italic-VariableFont_wght.ttf') format('truetype-variations'),
    url('../fonts/muli/Mulish-Regular.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'muli';
  font-style: normal;
  font-weight: 200; // Extra Light @see https://developer.mozilla.org/en-US/docs/web/css/@font-face/font-weight#common_weight_name_mapping
  src: url('../fonts/muli/Mulish-Light.ttf') format('truetype-variations'),
    url('../fonts/muli/Mulish-Regular.ttf') format('truetype');
}

/* bold for older browsers */
@font-face {
  font-display: swap;
  font-family: 'muli';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/muli/Mulish-VariableFont_wght.ttf') format('truetype-variations'),
    url('../fonts/muli/Mulish-Bold.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'TradeGothicLT';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/TradeGothicLT-BoldCondTwenty.otf');
  src: local('TradeGothicLT-BoldCondTwenty'),
    url('../fonts/TradeGothicLT-BoldCondTwenty.otf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 200;
  src: url('../fonts/RobotoSlab/RobotoSlab-Thin.ttf');
  src: local('RobotoSlab-Thin'),
    url('../fonts/RobotoSlab/RobotoSlab-Thin.ttf') format('truetype');
}

.font--title {
  font-family: $font-family !important;
}

.font--copy {
  font-family: $font-family-copy !important;
}

.font--vrm {
  font-family: $font-family-vrm !important;
  font-weight: bold;
}

h1,
.h1m {
  color: $navy-dark;
  font-size: 2.2rem;
  text-align: center;

  @include min-desktop {
    font-size: 3.4rem;
  }
}

h2,
.h2m {
  font-size: 2rem;
  text-align: center;

  @include min-desktop {
    font-size: 3rem;
  }
}

h3,
.h3m {
  font-size: 1.9rem;

  @include min-desktop {
    font-size: 2.8rem;
  }
}

h4,
.h4m {
  font-size: 1.8rem;

  @include min-desktop {
    font-size: 2.4rem;
  }
}

h5,
.h5m {
  font-size: 1.7rem;

  @include min-desktop {
    font-size: 2.2rem;
  }
}

h6,
.h6m {
  font-size: 1.6rem;

  @include min-desktop {
    font-size: 2rem;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1m,
.h2m,
.h3m,
.h4m,
.h5m,
.h6m {
  font-weight: bold;
  line-height: $line-height;
}

p {
  font-family: $font-family-copy;
  font-weight: normal;
  line-height: $line-height;
  margin-bottom: 1em;
}

span {
  line-height: $line-height;
}

$text-sizes: (
  'xl': 2rem,
  'l': 1.8rem,
  'm': 1.6rem,
  's': 1.4rem,
  'xs': 1.2rem,
);

@each $sizePrefix, $size in $text-sizes {
  .text-#{$sizePrefix} {
    font-size: $size;
  }
}

a {
  color: var(--link-color, $navy);

  &:hover {
    color: var(--link-color--hover, $navy-lite);
  }

  &:active {
    color: var(--link-color--active, $navy-lite);
  }
}

.text-normal {
  font-weight: normal;
}

sup {
  font-size: smaller;
  vertical-align: super;
}

strong,
.text-bold {
  font-weight: $font-weight-heavy;
}

em,
.text-italic {
  font-style: italic;
}

.text--no-underline {
  text-decoration: none;
}

.text--underline {
  text-decoration: underline;
}

.text--capitalize {
  text-transform: capitalize;
}

.text--uppercase {
  text-transform: uppercase;
}

.text--lowercase {
  text-transform: lowercase;
}

.text--pre-wrap {
  white-space: pre-wrap;
}

.text-content {
  ul {
    font-family: $font-family-copy;
    list-style: initial;
    padding-left: 2rem;

    li {
      margin-bottom: 1em;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1m,
  .h2m {
    color: $navy-dark;
  }
}

$text-align-variants: left, right, center;

@each $textAlignVariant in $text-align-variants {
  .text-#{$textAlignVariant} {
    text-align: $textAlignVariant;
  }
}

@include max-mobile {
  @each $textAlignVariant in $text-align-variants {
    .text-#{$textAlignVariant}-xs {
      text-align: $textAlignVariant;
    }
  }
}

@include min-tablet {
  @each $textAlignVariant in $text-align-variants {
    .text-#{$textAlignVariant}-sm {
      text-align: $textAlignVariant;
    }
  }
}

@include min-desktop {
  @each $textAlignVariant in $text-align-variants {
    .text-#{$textAlignVariant}-md {
      text-align: $textAlignVariant;
    }
  }
}

@include min-large {
  @each $textAlignVariant in $text-align-variants {
    .text-#{$textAlignVariant}-lg {
      text-align: $textAlignVariant;
    }
  }
}


.panel-header {
  color: $navy-dark;
  font-size: 2rem;
  font-weight: $font-weight-heavy;
  margin-bottom: 1rem;
  text-align: center;
  width: 100%;

  @include min-tablet {
    font-size: 2.4rem;
  }

  &--lg {
    font-size: 2.4rem;

    @include min-tablet {
      font-size: 2.8rem;
    }
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-1rem);
  }

  60% {
    transform: translateY(-0.5rem);
  }
}

.bounce-link {
  padding-bottom: 1rem;
  text-decoration: none;

  &--white {
    color: $white;

    svg {
      fill: $white;
    }

    &:hover {
      color: $white;
      text-decoration: underline;
    }
  }

  &--blue {
    color: $navy-dark;

    svg {
      fill: $navy-dark;
    }

    &:hover {
      color: $navy-dark;
      text-decoration: underline;
    }
  }

  i {
    animation-duration: 1.5s;
    animation-fill-mode: both;

    animation-iteration-count: 3;
    animation-name: bounce;
    display: inline-block;
    font-size: 2.5rem;
    margin-right: 0;
    position: relative;
    vertical-align: middle;
  }
}



$column-sizes: 2, 3, 4;

@each $column-size in $column-sizes {
  .text-columns-#{$column-size} {
    columns: $column-size;
  }
}

@include min-tablet {
  @each $column-size in $column-sizes {
    .text-columns-#{$column-size}-sm {
      columns: $column-size;
    }
  }
}

@include min-desktop {
  @each $column-size in $column-sizes {
    .text-columns-#{$column-size}-md {
      columns: $column-size;
    }
  }
}
