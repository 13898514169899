@import '../../mixins';
@import '../../helpers/variables';

.dark-theme {
  --gpanel--bg-color1: #07112e;
  --gpanel--bg-color2: #{$black};

  --gpanel--bg-gradient1-start: #020406;
  --gpanel--bg-gradient1-end: #192836;

  --gpanel--bg-gradient2-start: #030d1b;
  --gpanel--bg-gradient2-end: #1a3f5e;

  --gpanel--color: #{$white};
  --gpanel--border-color: #{$blue-light};
}

.mpanel {
  background: #fff;
  box-shadow: 0 0 0.5rem 0 rgba(208, 208, 208, 0.6);
  margin-bottom: 2rem;
  width: 100%;

  &--padding {
    margin-bottom: $col-gutter-mobile;
    padding: $box-gutter-mobile;

    @include min-tablet {
      margin-bottom: $col-gutter;
      padding: $box-gutter;
    }
  }

  &--rounded {
    border-radius: 1rem;
    overflow: hidden;
  }

  &--shadow {
    box-shadow: 1px 5px 5px 0 $grey-light;
  }
}

.gpanel {
  background: radial-gradient(circle, var(--gpanel--bg-color1, $white) 0%, var(--gpanel--bg-color2, #f7f7f7) 35%);
  border: 0.1rem solid var(--gpanel--border-color, $grey);
  border-radius: 1rem;
  color: var(--gpanel--color, $navy-dark);
  overflow: hidden;
  width: 100%;

  &--sticky {
    position: sticky;
    top: 0;
  }

  &--padded {
    padding: $box-gutter-mobile;

    @include min-tablet {
      padding: $box-gutter;
    }
  }

  &--gradient1 {
    background: linear-gradient(0deg, var(--gpanel--bg-gradient1-start, #f7f7f7) 0%, var(--gpanel--bg-gradient1-end, #f7f7f7) 100%);
  }

  &--gradient2 {
    background: linear-gradient(0deg, var(--gpanel--bg-gradient2-start, #f7f7f7) 0%, var(--gpanel--bg-gradient2-end, #f7f7f7) 100%);
  }

  &--transparent {
    background: none;
  }

  &__text {
    color: var(--gpanel--color, $navy-dark);
  }
}
