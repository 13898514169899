.review-stars {
  display: flex;
  justify-content: center;
  padding: 0 20px;
  text-decoration: none;

  img {
    margin-right: 5px;
    max-height: 35px;
  }
}
