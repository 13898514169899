@import '../helpers/variables';
@import '../mixins';

.dark-theme {
  --border-color: #{$white};
}

.joinflow-theme {
  --radio-hover: #{$blue};
}

.form__radio,
.form__checkbox {
  position: relative;

  input[type=radio],
  input[type=checkbox] {
    height: 2rem;
    left: 0;
    margin: 0.3rem 0 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 2rem;
    z-index: 2;

    + label::after {
      content: none;
    }

    &:checked + label::after {
      content: '';
    }

    &:focus + label::before {
      border-color: $orange;
    }
  }

  input[type=checkbox]:checked + label::before {
    background-color: $navy-lite;
  }

  label {
    color: $grey-darkest;
    cursor: default;
    display: inline-block;
    line-height: 2rem;
    padding-bottom: 0;
    padding-left: 2.5rem;
    position: relative;

    &::after,
    &::before {
      content: '';
      display: inline-block;
      pointer-events: none;
      position: absolute;
    }

    &::before {
      border: 0.2rem solid var(--border-color, #{$navy-lite});

      border-radius: 0.2rem;
      height: 1.6rem;
      left: 0;
      width: 1.6rem;
    }

    .form__field--invalid &::before {
      border-color: $red;
    }
  }
}

.form__radio label {
  &::before {
    border-radius: 50%;
  }

  &::after {
    background-color: $navy-lite;
    border-radius: 50%;
    height: 1.4rem;
    left: 0.3rem;
    top: 0.3rem;
    width: 1.4rem;
  }
}

.form__checkbox label {
  &::after {
    border-bottom: 0.1rem solid $white;
    border-left: 0.1rem solid $white;
    height: 0.5rem;
    left: 0.5rem;
    top: 0.5rem;
    transform: rotate(-45deg);
    width: 0.9rem;
  }
}

// Radio button groups
.form__field--radio-button-group {
  input[type=radio] {
    cursor: pointer;
    height: 100%;
    left: 0;
    margin: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .frbg__label {
    border: 0.2rem solid $grey;
    border-radius: 0.4rem;
    color: $grey;
    cursor: pointer;
    display: block;
    font-size: 2rem;
    height: 4rem;
    line-height: 3.6rem;
    margin: 0 1.5rem 0 0;
    padding: 0 1rem;
    text-align: center;
    text-decoration: none;
    width: 100%;
  }

  input[type=radio] ~ .frbg__label:hover,
  input[type=radio]:hover ~ .frbg__label {
    border: 0.2rem solid var(--radio-hover , $navy-lite);
    color: var(--radio-hover , $navy-lite);
  }

  input[type=radio]:checked ~ .frbg__label,
  input[type=radio]:checked ~ .frbg__label:hover {
    background-color: $navy-lite;
    border: 0.2rem solid $navy-lite;
    color: $white;
  }

  .frbg__container {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.5rem -1rem;
    @include min-tablet {
      padding-top: 0.5rem;
    }

    &--stacked {
      flex-direction: column;
    }
  }

  .frbg__item {
    margin: 0 0.5rem 1rem;
    min-width: calc(25% - 1rem);
    position: relative;

    @include max-tablet {
      min-width: calc(50% - 1rem);
    }
  }

  &--white {
    .frbg__label {
      background-color: $white;
      border-color: $navy-lite;
      color: $navy-dark;
      font-size: 16px;
      font-weight: bold;
    }

    .frbg__item {
      flex: 1;
    }

    input[type=radio]:hover ~ .frbg__label {
      background-color: $navy-lite;
      border-color: $navy-lite;
      color: $white;
    }
  }

  &--orange {
    .frbg__label {
      background-color: $orange;
      border-color: $orange;
      color: $white;
      font-size: 16px;
      font-weight: bold;
    }

    .frbg__item {
      flex: 1;
    }

    input[type=radio]:hover ~ .frbg__label {
      background-color: lighten($orange, 5%);
      border-color: lighten($orange, 5%);
      color: $white;
    }
  }
}
