@import '../../mixins';
@import '../../helpers/variables';

.buffer {
  animation: buffer 1.5s linear infinite;
  background-color: #fff;
  background-image: url('../../img/svg/buffer-2.svg');
  background-repeat: repeat-x;
  background-size: 50%;
  display: none;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  will-change: background-position;

  &--rounded {
    border-radius: 4px;
  }
}

.loading {
  position: relative;

  .button--cta,
  &.button--cta {
    .buffer {
      border-bottom-right-radius: 50px;
      border-top-right-radius: 50px;
      width: calc(100% - 10px);
    }
  }

  .buffer {
    display: block;
  }
}

.loading--dropdown .buffer {
  border-radius: 5px;
  z-index: 15;
}

@keyframes buffer {
  0% {
    background-position: 0 50%;
  }

  100% {
    background-position: 100% 50%;
  }
}
