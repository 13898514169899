.tick-list {
  list-style-position: outside;

  li {
    font-size: 1.8rem;
    font-weight: bold;
    margin-top: 1rem;
    padding-left: 3.5rem;
    position: relative;

    &:first-child {
      margin-top: 0;
    }

    &::before {
      color: #00cfe6;
      content: url('../../../icons/tick.svg');
      left: 0;
      position: absolute;
      top: 0;
    }
  }

  &--light {
    li {
      font-weight: normal;
    }
  }

  &--orange {
    li::before {
      content: url('../../../icons/tick--orange.svg');
    }

    &--circle {
      li {
        padding-left: 5.5rem;
      }

      li::before {
        border-radius: 50%;
        box-shadow: 0 0 5px rgba(70, 70, 70, 0.2);
        content: url('../../../icons/tick--orange-40.svg');
        display: inline-block;
        height: 40px;
        width: 40px;
      }
    }
  }

}

.icon-list {
  &__item {
    align-items: center;
    display: flex;
    margin-bottom: 2rem;
  }

  &__icon {
    display: block;
    fill: currentColor;
    flex-shrink: 0;
    height: 2.4rem;
    margin-right: 2rem;
    width: 2.4rem;
  }
}
