@keyframes ckw {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spin {
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: ckw;
  transform-origin: 50% 50%;
}
