@import '../helpers/variables';
@import '../mixins';

.main-nav__body--fixed {
  overflow: hidden;
}

.main-nav {
  height: calc(100% - 98px);
  left: 100%;
  pointer-events: none;
  position: fixed;
  top: 98px;
  width: 100%;
  z-index: 500;

  &--visible {
    pointer-events: auto;
  }

  .app-menu {
    background-color: rgba(0, 0, 0, 0.7);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
    color: $white;

    font-family: $font-family-copy;
    font-size: 15px;
    height: 100%;
    overflow-y: auto;
    pointer-events: auto;
    position: relative;
    transform: none;
    width: 100%;
    will-change: transform;
    z-index: 160;

    @include min-tablet {
      width: 300px;
    }
  }

  &--opened .app-menu {
    transform: translateX(-100%);
  }

  &--animatable .app-menu {
    transition: all 250ms ease;
  }

  &::after {
    background: rgba(0, 0, 0, 0.4);
    content: '';
    display: block;
    height: 100%;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    transition: opacity 250ms ease;
    width: 100%;
    will-change: opacity;
  }

  &--opened.main-nav::after {
    opacity: 1;
    pointer-events: auto;
  }

  &__item {
    background-color: $navy-dark;
    cursor: pointer;
    padding: 20px;

    &--has-children {
      max-height: 65px;
      overflow: hidden;
      padding-bottom: 0;
      transition: max-height 0.5s;
      will-change: max-height;

      // display arrow towards bottom when closed
      > .main-nav__icon {
        transform: rotate(90deg);
        transition: all 0.5s;
      }

      &.main-nav__item--open {
        margin-bottom: 0;
        max-height: 975px;

        // display arrow towards top when open
        > .main-nav__icon {
          transform: rotate(-90deg);
        }
      }
    }

    &--top {
      border-bottom: 0;
      display: flex;
      justify-content: space-between;

      @include min-tablet {
        border-bottom: 1px solid $grey-pale;
      }
    }
  }

  // "a" element inside "item"
  &__link {
    color: $white;
    display: block;
    margin: -20px;
    padding: 20px;

    &:hover,
    &:active {
      color: $white;
    }
  }


  // "ul" element inside "item"
  &--submenu {
    margin: 23px -20px 0;

    .main-nav__item {
      background-color: $navy;
    }

    .main-nav__text {
      color: $white;
      font-weight: $font-weight-regular;
    }

    .main-nav__icon {
      color: $white;
    }
  }

  &__text {
    font-weight: $font-weight-heavy;
    pointer-events: none;
  }

  &__icon {
    color: $white;
    display: inline-block;
    float: right;
    pointer-events: none;
  }
}
