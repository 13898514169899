@import '../mixins';
@import '../helpers/variables';

.button {
  appearance: none;
  background-color: $navy;
  border: 1px solid $navy;
  border-radius: 4px;

  color: $white;
  cursor: pointer;
  display: inline-block;
  font-family: $font-family;
  font-size: 19px;
  font-weight: bold;
  line-height: 40px;
  min-height: 44px;
  overflow: visible;
  padding: 0 15px;
  text-align: center;
  text-decoration: none;


  @include min-tablet {
    font-size: 20px;
    line-height: 50px;
    min-height: 54px;
    padding: 0 24px;
  }

  &--medium {
    font-size: 19px;
    line-height: 40px;
    min-height: 44px;
  }

  &--small {
    font-size: 14px;
    line-height: 32px;
    min-height: 36px;
  }

  &:hover {
    background-color: $navy-lite;
    border-color: $navy-lite;
    color: $white;
  }

  &:focus,
  &:active,
  &--active {
    background-color: $navy-lite;
    border-color: $navy-lite;
    color: $white;
    outline: none;
  }

  &:disabled {
    background-color: $navy-lite;
    border-color: $navy-lite;
    color: $grey-light;
    cursor: default;
    opacity: 0.5;
  }

  &--cta {
    background-color: $orange;
    border-color: $orange;
    color: $white;

    &:hover {
      background-color: $orange-light;
      border-color: $orange-light;
      color: $white;
    }

    &:focus,
    &:active,
    &.button--active {
      background-color: $orange-dark;
      border-color: $orange-dark;
      color: $white;
    }

    &:disabled {
      background-color: $orange;
      border-color: $orange;
      color: $white;
      opacity: 0.5;
    }
  }

  &--green {
    background-color: $green;
    border-color: $green;
    color: $white;

    &:hover {
      background-color: $grey-light;
      border-color: $grey-light;
      color: $white;
    }

    &:focus,
    &:active,
    &.button--active {
      background-color: $green-dark;
      border-color: $green-dark;
      color: $white;
    }

    &:disabled {
      background-color: lighten($green, 40%);
      border-color: lighten($green, 40%);
      color: $white;
      opacity: 0.5;
    }
  }

  &--orange {
    background-color: $orange;
    border-color: $orange;
    color: $white;

    &:hover {
      background-color: darken($orange, 10%);
      border-color: darken($orange, 10%);
      color: $white;
    }

    &:focus,
    &:active,
    &.button--active {
      background-color: darken($orange, 10%);
      border-color: darken($orange, 10%);
      color: $white;
    }

    &:disabled {
      background-color: lighten($orange, 40%);
      border-color: lighten($orange, 40%);
      color: $white;
      opacity: 0.5;
    }
  }

  &--ghost {
    background-color: transparent;
    border-color: $navy;
    color: $navy;

    &:hover {
      background-color: $navy-lite;
      border-color: $navy-lite;
      color: $white;
    }

    &:focus,
    &:active,
    &.button--active {
      background-color: transparent;
      border-color: $navy-lite;
      color: $navy-lite;
    }

    &:disabled {
      background-color: transparent;
      border-color: $navy-lite;
      color: $grey-light;
      opacity: 0.5;
    }
  }


  &--ghost-inversed {
    background-color: transparent;
    border-color: $white;
    color: $white;

    &:hover {
      background-color: $navy-lite;
      border-color: $white;
      color: $white;
    }

    &:focus,
    &:active,
    &.button--active {
      background-color: transparent;
      border-color: $white;
      color: $white;
    }

    &:disabled {
      background-color: transparent;
      border-color: $grey-light;
      color: $grey-light;
      opacity: 0.5;
    }
  }

  &--full {
    width: 100%;
  }

  &--partners {
    align-items: center;
    background: $white 0 0 no-repeat padding-box;
    border-color: $white;
    border-radius: 20px;
    box-shadow: 0 0 10px $grey-dark;
    color: $black;
    cursor: default;
    display: flex;
    height: 114px;
    justify-content: center;
    opacity: 1;

    &:hover {
      background-color: $white;
      border-color: $grey-light;
      color: $black;
    }

  }
}

.btn-group {
  font-size: 0;

  @include max-mobile {
    .button {
      border-radius: 4px !important;
    }
  }

  > .button:not(:last-child) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  > .button:not(:first-child) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
}
