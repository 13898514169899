@import '../helpers/variables';
@import '../mixins';

// universal
html {
  font-size: 10px;
}

body {
  background: $grey-pale;
  color: $grey-darkest;
  font-family: $font-family;
  font-size: 1.6rem;
  line-height: $line-height;
  margin: 0;
  padding: 0;
}

nav a {
  text-decoration: none;
}

hr {
  background-color: $grey;
  border: 0;
  clear: both;
  height: 2px;
  margin: $col-gutter auto;
  max-width: 75rem;
  padding: 0;
}

// SVG
svg {
  display: block;
  max-height: 100%;
  max-width: 100%;
}


.content {
  background-color: $grey-pale;
  transition: transform 0.25s;
}

//body > .content {
//  position: relative;
//}

.left {
  float: left;
}

.right {
  float: right;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

.relative {
  position: relative;
}

.hidden {
  display: none !important;
  visibility: hidden;
}

[v-cloak] {
  display: none !important;
}

.cover-block {
  display: inline-block;
  height: 100%;
  width: 100%;
}

.has-bg-img {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  &--contain {
    background-size: contain;
  }
}

.zoom-bg {
  overflow: hidden;
  padding-bottom: 56%;
  position: relative;

  &__frame {
    height: 100%;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;

    &:hover .zoom-bg__img {
      transform: scale(1.1);
    }
  }

  &__overlay {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 50%);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &__overlay--full {
    background: rgba(0, 0, 0, 0.5);
  }

  &__img {
    height: 100%;
    max-width: 100%;
    object-fit: cover;
    transition: transform 0.4s;
    width: 100%;
  }

  &__cat {
    background-color: $navy-dark;
    bottom: 0;
    color: $white;
    display: block;
    font-size: $font-small;
    padding: 2px 5px;
    position: absolute;
    right: 0;
    text-transform: capitalize;
  }

  &__rating {
    bottom: 15px;
    display: block;
    filter: drop-shadow(0 0 2px #000);
    height: 65px;
    left: 15px;
    position: absolute;
    width: 65px;
  }
}

.responsiveImg {
  height: auto;
  max-width: 100%;
}

.vertical-center-img {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.caption {
  clear: left;
  display: block;
  font-size: 13px;
  margin-top: 5px;
}

// Tables
table,
.table {
  td,
  th {
    border: 1px solid $grey;
    padding: 10px;
  }

  th {
    font-weight: bold;
  }

  @include max-mobile {
    td,
    th {
      padding: 5px;
    }
  }

}

.clear {
  clear: both;
}

.arrow {
  display: inline-block;
  fill: $grey;
  float: right;
  height: 17px;
  position: relative;

  &__good {
    fill: $blue;
  }

  &.arrow--left {
    display: inline;
    float: none;
  }
}

.chevron {
  svg {
    display: inline-block;
    height: 1.3em;
    left: -0.3em;
    position: relative;
    vertical-align: middle;
    width: 1.3em;
  }

  &--orange {
    svg {
      fill: $orange;
    }
  }
}

.how__icon {
  color: $orange;
  display: inline-block;
  fill: $orange;
  height: 50px;
  width: 50px;
}

.pointer {
  cursor: pointer;
}

.brtd-special--panel {
  background: #f6f6f6;
  border-top: 2px solid $navy;
  padding: 20px;
  text-align: center;
}

.icon {
  fill: $navy-dark;
  max-width: 100%;
}

.hpto__container {
  max-width: 1000px;
  padding: 150px 0 0;
  pointer-events: none;
  position: relative;
}

.hpto__content {
  background-color: $grey-pale;
  padding: 20px;
  pointer-events: all;
}

.hpto__ad {
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: absolute;
  width: 100%;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}
