@import '../../helpers/variables';
@import '../../mixins';

.stars {
  align-items: center;
  display: flex;
  position: relative;

  &--centered {
    justify-content: center;
  }

  &__label {
    display: inline-block;
    margin-right: 1rem;
  }

  &__icons {
    position: relative;
    white-space: nowrap;

    &--full {
      color: $yellow;
      left: 0;
      overflow: hidden;
      position: absolute;
      top: 0;
      white-space: nowrap;
      z-index: 2;
    }

    &--empty {
      color: $grey;
      z-index: 1;
    }

    &--background {
      color: $white;
      left: 0;
      overflow: hidden;
      position: absolute;
      top: 0;
      white-space: nowrap;
      z-index: 0;

      .star {
        transform: scale(1.6);
      }
    }

    .star {
      display: inline-block;
      //top: -5px;
      position: relative;
      vertical-align: middle;
      width: 16px;

      svg {
        max-width: 100% !important;
      }
    }
  }

  &--with-bg {
    .star {
      margin: 0 3px;
    }
  }
}


.star__basic {
  color: $grey;
  display: inline-block;
  fill: $grey;
  float: left;
  height: 16px;
  position: relative;
  top: 3px;
  width: 16px;

  &--full {
    color: $yellow;
    fill: $yellow;
  }


  &--lg {
    height: 20px;
    margin-right: 5px;
    width: 20px;
  }

  @include min-tablet {
    &--lg {
      height: 25px;
      width: 25px;
    }
  }
}
