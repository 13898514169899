@import '../../helpers/variables';

.tokenMessage {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;

  &__content {
    background-color: $white;
    max-width: 600px;
  }
}
