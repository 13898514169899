@import '../helpers/variables';
@import '../mixins';

.dark-theme {
  --separator-color: #{$navy};
  --separator-color--inversed: #{$grey-pale};
}

.s-right {
  position: relative;

  &::before {
    background-color: var(--separator-color, $grey-pale);
    content: '';
    height: 80%;
    position: absolute;
    right: 0;
    top: 10%;

    width: 0.2rem;
  }

  &:last-child::before {
    display: none;
  }

  &--inversed::before {
    background-color: var(--separator-color--inversed, $navy);
  }

  &--thin::before {
    width: 1px;
  }

  @include max-tablet {
    &::before {
      display: none;
    }
  }
}

.s-bottom {
  position: relative;

  &::after {
    background-color: var(--separator-color, $grey-pale);
    bottom: 0;
    content: '';

    height: 0.2rem;
    position: absolute;
    right: 10%;
    width: 80%;
  }

  &--inversed::after {
    background-color: var(--separator-color--inversed, $navy);
  }

  &--thin::after {
    height: 1px;
  }
}

.s-row {
  position: relative;

  &::after {
    background-color: var(--separator-color, $grey-pale);
    bottom: 0;
    content: '';

    height: 0.1rem;
    position: absolute;
    right: 0;
    width: 100%;
  }

  &:last-child::after {
    display: none;
  }

  &--inversed::after {
    background-color: var(--separator-color--inversed, $navy);
  }

  &--thin::after {
    height: 1px;
  }
}

@include only-tablet {
  .s-right-none-sm::before {
    display: none;
  }
}

@include only-desktop {
  .s-right-none-md::before {
    display: none;
  }
}

@include min-tablet {
  .s-bottom-none-sm::after {
    display: none;
  }
}

@include min-desktop {
  .s-bottom-none-md::after {
    display: none;
  }
}

@include min-large {
  .s-bottom-none-lg::after,
  .s-right-none-lg::before {
    display: none;
  }
}
