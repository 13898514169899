@import 'variables';

@mixin fa-icon {
  display: inline-block;
  font-family: 'Font Awesome 5 Free';
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  font-weight: 900;
  text-rendering: auto;
}

// Media Queries Mixins
@mixin min-mobile {
  @media (min-width: $breakpoint-mobile) {
    @content;
  }
}

@mixin max-mobile {
  @media (max-width: calc(#{$breakpoint-mobile} - 1px)) {
    @content;
  }
}

@mixin min-tablet {
  @media (min-width: $breakpoint-tablet) {
    @content;
  }
}

@mixin max-tablet {
  @media (max-width: ($breakpoint-tablet - 1)) {
    @content;
  }
}

@mixin only-tablet {
  @media (min-width: $breakpoint-tablet) and (max-width: ($breakpoint-desktop - 1)) {
    @content;
  }
}


@mixin min-desktop {
  @media (min-width: $breakpoint-desktop) {
    @content;
  }
}

@mixin max-desktop {
  @media (max-width: calc(#{$breakpoint-desktop} - 1px)) {
    @content;
  }
}

@mixin only-desktop {
  @media (min-width: $breakpoint-desktop) and (max-width: ($breakpoint-large - 1)) {
    @content;
  }
}

@mixin min-large {
  @media (min-width: $breakpoint-large) {
    @content;
  }
}

@mixin max-large {
  @media (max-width: calc(#{$breakpoint-large} - 1px)) {
    @content;
  }
}

@mixin min-xlarge {
  @media (min-width: $breakpoint-xlarge) {
    @content;
  }
}

@mixin max-xlarge {
  @media (max-width: calc(#{$breakpoint-xlarge} - 1px)) {
    @content;
  }
}

@mixin placeholder {
  &::placeholder {
    @content;
  }
}
