@import '../helpers/variables';

.alert {
  background-color: $red;
  border-radius: 2px;
  color: $white;
  padding: 10px 20px;

  &--success {
    background-color: $blue;
  }

  &--info {
    background-color: $blue;
  }

  &--warning {
    background-color: $orange;
  }

  &--danger {
    background-color: $red;
  }
}
