@import '../../helpers/variables';
@import '../../mixins';

$users-color: $navy-dark;
$features-color: #999;

.trust-bar {
  align-items: center;
  display: none;
  justify-content: space-between;
  padding-bottom: 15px;
  padding-top: 15px;
  @include min-tablet {
    display: flex;
  }

  &__item {
    align-items: center;
    display: flex;
  }

  &__partners {
    color: $features-color;
    font-size: 13px;
    font-weight: bold;
    text-transform: uppercase;
  }

  &__partner {
    height: 30px;
    margin: 0 10px;

    svg {
      fill: $features-color;
      height: 100%;
    }
  }

  // bigger stars if inside trust bar
  .stars__icons .star {
    top: -2px;
    width: 18px;
    @include min-large {
      width: 26px;
    }
  }

  &__text {
    color: $users-color;
    font-size: 17px;
    font-weight: bold;

    i {
      display: inline-block;
      height: 40px;
      margin-right: 15px;
    }

    svg,
    img {
      fill: $users-color;
      height: 40px;
    }
  }

  &__list-item {
    align-items: center;
    color: $navy-dark;
    display: flex;
    font-size: 13px;
    font-weight: 700;
    text-transform: capitalize;

    &:not(:last-child) {
      margin-right: 10px;
    }

    svg {
      fill: $navy;
      margin-right: 5px;
    }

    &--blue {
      color: $blue-regit;
    }
  }
}


.white-trust-bar {
  align-items: center;
  display: none;
  justify-content: space-between;
  padding-bottom: 15px;
  padding-top: 15px;
  @include min-tablet {
    display: flex;
  }

  &__item {
    align-items: center;
    display: flex;
  }

  &__text {
    color: $white;
    font-size: 17px;
    font-weight: bold;

    i {
      display: inline-block;
      height: 40px;
      margin-right: 15px;
    }

    svg,
    img {
      fill: $white;
      height: 40px;
    }
  }

  &__list-item {
    align-items: center;
    color: $white;
    display: flex;
    font-size: 13px;
    font-weight: 700;
    text-transform: capitalize;

    &:not(:last-child) {
      margin-right: 10px;
    }

    svg {
      fill: $white;
      margin-right: 5px;
    }

    &--black {
      color: $black;

      svg {
        fill: $black;
      }
    }
  }
}
