@use 'sass:math';
@import '../helpers/variables';
@import '../mixins';


* {
  box-sizing: border-box;
}

.container {
  margin-left: auto;
  margin-right: auto;
  padding: 0 $col-gutter-mobile;
  width: 100%;

  @include min-mobile {
    max-width: $breakpoint-mobile;
  }

  @include min-tablet {
    max-width: $breakpoint-tablet;
    padding: 0 $col-gutter;
  }

  @include min-desktop {
    max-width: $breakpoint-desktop;
  }

  @include min-large {
    max-width: $breakpoint-large;
  }

  &--900 {
    margin: 0 auto;
    max-width: 900px;
  }

  @include min-desktop {
    &--940 {
      max-width: 940px;
    }
  }

  &__spacing {
    padding: 15px $col-gutter-mobile;
  }

  @include min-tablet {
    &__spacing {
      padding: 20px $col-gutter;
    }
  }

  &--1000 {
    @include min-tablet {
      max-width: 1040px;
    }

    @include min-desktop {
      max-width: 1040px;
    }
  }

  &--fluid {
    max-width: none;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: math.div($col-gutter-mobile, -2);
  margin-right: math.div($col-gutter-mobile, -2);

  @include min-tablet {
    margin-left: math.div($col-gutter, -2);
    margin-right: math.div($col-gutter, -2);
  }

  // make items same height
  &.equal-height > * {
    display: flex;
  }

  &.equal-height.spaced > * {
    flex-direction: column;
    justify-content: space-between;
  }

  &.centered {
    align-items: center;
  }
}

.row [class*='col-'] {
  min-height: 1px;
  padding-left: math.div($col-gutter-mobile, 2);
  padding-right: math.div($col-gutter-mobile, 2);
  width: 100%;

  @include min-tablet {
    padding-left: math.div($col-gutter, 2);
    padding-right: math.div($col-gutter, 2);
  }
}


.no-gutters {
  margin-left: 0;
  margin-right: 0;

  > .col,
  > [class*='col-'] {
    padding-left: 0;
    padding-right: 0;
  }
}

@mixin make-col($size) {
  flex: 0 0 percentage(math.div($size, 12));
  // Add a `max-width` to ensure content within each column does not blow out
  // the width of the column. Applies to IE10+ and Firefox. Chrome and Safari
  // do not appear to require this.
  max-width: percentage(math.div($size, 12));
}

@mixin make-col-offset($size) {
  $num: math.div($size, 12);
  margin-left: if($num == 0, 0, percentage($num));
}

@for $i from 1 through 12 {
  .col-#{$i} {
    @include make-col($i);
  }
}

@for $i from 0 through 11 {
  .offset-#{$i} {
    @include make-col-offset($i);
  }
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

@include max-mobile {
  @for $i from 1 through 12 {
    .col-#{$i}-xs {
      @include make-col($i);
    }
  }
  @for $i from 0 through 11 {
    .offset-#{$i}-xs {
      @include make-col-offset($i);
    }
  }

  .col-auto-xs {
    flex: 0 0 auto;
    max-width: 100%;
    width: auto;
  }
}

@include min-tablet {
  @for $i from 1 through 12 {
    .col-#{$i}-sm {
      @include make-col($i);
    }
  }

  @for $i from 0 through 11 {
    .offset-#{$i}-sm {
      @include make-col-offset($i);
    }
  }

  .col-fixed-330-sm {
    flex: 0 0 330px;
    max-width: 100%;
    width: auto;
  }

  .col-auto-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    width: auto;
  }
}

@include min-desktop {
  @for $i from 1 through 12 {
    .col-#{$i}-md {
      @include make-col($i);
    }
  }
  @for $i from 0 through 11 {
    .offset-#{$i}-md {
      @include make-col-offset($i);
    }
  }

  .col-fixed-300-md {
    flex: 0 0 320px;
    max-width: 100%;
    width: auto;
  }

  .col-auto-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    width: auto;
  }

  .flex-row-reverse-md {
    flex-direction: row-reverse !important;
  }

  .flex-column-reverse-md {
    flex-direction: column-reverse !important;
  }
}

@include min-large {
  @for $i from 1 through 12 {
    .col-#{$i}-lg {
      @include make-col($i);
    }
  }

  @for $i from 0 through 11 {
    .offset-#{$i}-lg {
      @include make-col-offset($i);
    }
  }

  .col-auto-lg {
    flex: 0 0 auto;
    max-width: 100%;
    width: auto;
  }

  .flex-row-reverse-lg {
    flex-direction: row-reverse !important;
  }

  .flex-column-reverse-lg {
    flex-direction: column-reverse !important;
  }
}
