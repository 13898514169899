@import '../mixins';

$display-variants: none, block, flex, inline, inline-block;

@each $displayVariant in $display-variants {
  .d-#{$displayVariant} {
    display: $displayVariant !important;
  }
}

@include max-mobile {
  @each $displayVariant in $display-variants {
    .d-#{$displayVariant}-xs {
      display: $displayVariant !important;
    }
  }
}


@include min-tablet {
  @each $displayVariant in $display-variants {
    .d-#{$displayVariant}-sm {
      display: $displayVariant !important;
    }
  }
}


@include min-desktop {
  @each $displayVariant in $display-variants {
    .d-#{$displayVariant}-md {
      display: $displayVariant !important;
    }
  }
}

@include min-large {
  @each $displayVariant in $display-variants {
    .d-#{$displayVariant}-lg {
      display: $displayVariant !important;
    }
  }
}

@include min-xlarge {
  @each $displayVariant in $display-variants {
    .d-#{$displayVariant}-xlg {
      display: $displayVariant !important;
    }
  }
}
