@import '../../mixins';
@import '../../helpers/variables';

.vrm {
  font-weight: bold;
  text-align: left;
  text-transform: uppercase;

  &::placeholder {
    color: #e5e5e5;
  }
}
