@import '../../helpers/variables';

.dark-theme {
  --tooltip-icon--bg: #{$white};
  --tooltip-icon--text: #{$navy-dark};

  --tooltip-panel--bg: #{$navy-dark};
  --tooltip-panel--text: #{$white};
  --tooltip-panel--border: #{$blue-light};
}

.tooltip {
  position: relative;

  &__icon {
    background-color: var(--tooltip-icon--bg, $navy);
    border-radius: 100%;
    color: var(--tooltip-icon--text, $white);
    cursor: pointer;
    display: inline-block;
    font-size: 10px;
    height: 16px;
    line-height: 16px;
    margin-left: 2px;
    text-align: center;
    vertical-align: middle;
    width: 16px;
  }

  &__content {
    background-color: var(--tooltip-panel--bg, $grey-pale);
    border: 1px solid var(--tooltip-panel--border, $grey-darkest);
    color: var(--tooltip-panel--text, $navy-dark);
    display: none;
    font-size: 14px;
    font-weight: normal;
    left: 0;
    min-width: 250px;
    padding: 10px;
    position: absolute;
    top: 30px;
    z-index: 50;


    .tooltip--opened & {
      display: block;
    }
  }
}
