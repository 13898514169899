@import '../../helpers/variables';
@import '../../mixins';

.dark-theme {
  --breadcrumb-color: #{$white};
}

.breadcrumbs-wrap {
  padding: 1.5rem 0;

  @include min-tablet {
    padding: 2rem 0;
  }
}

.breadcrumbs {
  list-style: none;
  margin: 0;
  padding: 0;

  &__item {
    color: var(--breadcrumb-color, #{$grey-darkest});
    display: inline-block;
    font-family: $font-family-copy;
    font-size: 1.2rem;

    a {
      color: var(--breadcrumb-color, #{$grey-darkest});
      font-weight: bold;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    &:not(:last-child)::after {
      content: ' > ';
      display: inline-block;
      margin: 0 0.3rem;
    }
  }
}
