@import '../../helpers/variables';
@import '../../mixins';

%heronew-overlay {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.heronew {
  background-position: center center;
  background-size: cover;
  padding: 80px 0;
  position: relative;

  h1,
  h2 {
    color: $white;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.45);
  }

  h2 {
    // same as h4 non bold
    font-size: 18px;
    font-weight: normal;

    @include min-desktop {
      font-size: 24px;
    }
  }

  &__content {
    position: relative;

    &--white {
      color: #fff;
      text-shadow: 0 1px 3px transparentize($black, 0.45);
    }
  }

  &__overlay {
    @extend %heronew-overlay;
    background-color: transparentize($black, 0.3);

    &--navy-radial {
      @extend %heronew-overlay;
      background: radial-gradient($navy-lite 0%, $navy-dark 100%);
    }

    &--blue {
      @extend %heronew-overlay;
      background: linear-gradient(0deg, transparentize($blue, 0.8) 0%, transparentize($blue, 0.6) 100%);
    }

    &--grey {
      @extend %heronew-overlay;
      background: linear-gradient(0deg, transparentize($black, 0.5) 0%, transparentize($black, 0.3) 100%);
    }
  }

  &__bg {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    &-img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  &__form {
    margin-top: 15px;

    @include min-large {
      margin-top: 30px;
    }

    .button {
      min-width: 200px;
    }
  }

  .landing-form {
    max-width: 360px;

    @include max-mobile {
      max-width: none;
    }

    &--centered {
      margin: 0 auto;
    }

    &--registration {
      background: $navy;
      border: 1px solid $white;
      border-radius: 3px;
      padding: $col-gutter-mobile;

      @include min-tablet {
        padding: $col-gutter;
      }

      a,
      a:hover,
      .form__hint {
        color: $white;
      }

      .form__checkbox label,
      .form__radio label {
        color: $white;

        &::before,
        &::before {
          border-color: $white;
        }
      }

      .form__radio input:checked + label::after {
        background-color: $orange;
      }
    }
  }

  &__arrow-wrap {
    bottom: 0;
    left: 0;
    position: absolute;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.45);
    width: 100%;
    z-index: 1;
  }

  &--overlapped {
    padding-bottom: 230px;
  }

  &__overlap-element {
    margin-top: -175px;
    position: relative;
  }
}
