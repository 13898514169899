// grid
$col-width: 100%;
$col-gutter-mobile: 1.5rem;
$col-gutter: 2rem;
$box-gutter-mobile: 1.5rem;
$box-gutter: 3rem;
$breakpoint-mobile: 480px; // 480px
$breakpoint-tablet: 768px; // 768px
$breakpoint-desktop: 1024px; // 1024px
$breakpoint-large: 1240px; // 1240px Includes extra 40px for Gutters.
$breakpoint-xlarge: 1600px; // 1240px Includes extra 40px for Gutters.

// fonts
$font-family-copy: 'muli', 'Arial', sans-serif;
$font-family: 'muli', sans-serif;
$font-family-vrm: 'TradeGothicLT', sans-serif;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-heavy: 700;
$line-height: 1.45;

// custom stuff
$mobile-leaderboard-bottom: 0 !default;

//font sizes
$font-xx-small: 1rem;
$font-x-small: 1.2rem;
$font-small: 1.4rem;
$font-large: 1.8rem;
$font-x-large: 2rem;
$font-xx-large: 2.2rem;
$panel-title-size: 2.4rem;
$main-title-size: 2.8rem;

// new Regit Theme
$navy: #081536;
$navy-lite: #162855;
$navy-dark: #040a1a;
$blue-regit: #1d4aff;

$grey-palest: #f7f7f7;
$grey-pale: #f1f1f1;
$grey-lightest: #e5e5e5;
$grey-light: #d0d0d0;
$grey: #cecece;
$grey-dark: #aaa;
$grey-darkest: #333447;

$blue-pale: #ecf7fe;
$blue-light: #8f9ec1;
$blue: #0089b3;

$green-pale: #dcfadc;

// Base colors
$white: #fff;
$black: #000;
$red: #e61600;
$yellow: #ffd432;
$orange: #ef6100;
$green: #7ed321;
$gold: #b08450;

// Color variants
$orange-light: lighten($orange, 10%);
$orange-dark: darken($orange, 5%);
$orange-darkest: darken($orange, 8%);
$green-light: lighten($green, 10%);
$green-dark: darken($green, 5%);

// Traffic light colors
$g-stop: #eb5346;
$g-go: #2cc41e;

// Brand Colours
$motoreasy-green: #00a88f;

$cf247-purple: #6905F2;
$cf247-green: #00D4B5;

$motorway-font-family: 'New Transport AA', 'New Transport', NewTransport, Helvetica, sans-serif;
$motorway-black: #1d1d1b;
$motorway-yellow: #fef200;
$motorway-blue: #0460cc;

$gocompare-green: #eef3cb;
$gocompare-gray: #f4f7f5;
$gocompare-pink: #e11970;
$gocompare-blue: #1279fd;
$gocompare-dark-green: #143c14;

$rounded-border-sizes: (
  'none': 0,
  'sm': .25rem, // 4px
  'md': .5rem,  // 8px
  'lg': 1rem,   // 16px
  'xl': 2rem,   // 32px
  'full': 100%
);
