@use 'sass:math';

$spaceamounts: (0, 10, 20, 30, 40, 50);
$sides: (top, bottom, left, right);

@each $space in $spaceamounts {
  .m-#{$space} {
    margin: #{math.div($space, 10)}rem !important;
  }

  .p-#{$space} {
    padding: #{math.div($space, 10)}rem !important;
  }
}

@each $space in $spaceamounts {
  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{math.div($space, 10)}rem !important;
    }

    .p#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{math.div($space, 10)}rem !important;
    }
  }
}
