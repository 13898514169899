:root {
  @each $size_name, $size_value in $rounded-border-sizes {
    --rounded-#{$size_name}: $size_value;
  }
}

.rounded {
  @each $size_name, $size_value in $rounded-border-sizes {
    &-#{$size_name} {
      border-radius: $size_value !important;
    }
  }

  @each $size_name, $size_value in $rounded-border-sizes {
    &-top-#{$size_name} {
      border-top-left-radius: $size_value !important;
      border-top-right-radius: $size_value !important;
    }
  }

  @each $size_name, $size_value in $rounded-border-sizes {
    &-tl-#{$size_name} {
      border-top-left-radius: $size_value !important;
    }
  }

  @each $size_name, $size_value in $rounded-border-sizes {
    &-tr-#{$size_name} {
      border-top-right-radius: $size_value !important;
    }
  }

  @each $size_name, $size_value in $rounded-border-sizes {
    &-bottom-#{$size_name} {
      border-bottom-left-radius: $size_value !important;
      border-bottom-right-radius: $size_value !important;
    }
  }

  @each $size_name, $size_value in $rounded-border-sizes {
    &-bl-#{$size_name} {
      border-bottom-left-radius: $size_value !important;
    }
  }

  @each $size_name, $size_value in $rounded-border-sizes {
    &-br-#{$size_name} {
      border-bottom-right-radius: $size_value !important;
    }
  }

  &-image {
    border-radius: map-get($rounded-border-sizes, 'lg');
  }

}
