@import '../helpers/variables';

$colors: (
  'navy': $navy,
  'navy-lite': $navy-lite,
  'navy-dark': $navy-dark,
  'grey-pale': $grey-pale,
  'grey-light': $grey-light,
  'grey': $grey,
  'grey-dark': $grey-dark,
  'grey-darkest': $grey-darkest,
  'blue': $blue,
  'blue-pale': $blue-pale,
  'orange': $orange,
  'white': $white,
  'black': $black,
  'green': $green,
  'red': $red,
  'g-go': $g-go,
  'g-stop': $g-stop,
  'regit-blue': $blue-regit,
  'regit-orange': $orange,
  'regit-green': $green
);

@each $color_name, $color_value in $colors {
  .#{$color_name} {
    background-color: $color_value !important;
  }
  .text-#{$color_name} {
    color: $color_value !important;
  }
}
