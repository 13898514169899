// Inputs
@import 'radio-checkbox-rgb';
@import '../helpers/variables';
@import '../mixins';

.dark-theme {
  --label-color: #{$white};
  --hint-color: #{$white};
}

.joinflow-theme {
  --input-bg-color: #{$navy-dark};
  --input-font-color: #{$white};
  --input-placeholder-color: #{$blue-light};
}

.form {
  &__field {
    display: inline-block;
    margin-bottom: 2rem;
    text-align: left;
    width: 100%;

    @include min-tablet {
      margin-bottom: 1.5rem;
    }
  }

  &__label {
    color: var(--label-color, #{$black});
    display: inline-block;
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    text-indent: 1rem;

    @include min-tablet {
      color: var(--label-color, #{$grey-darkest});
      font-size: 1.4rem;
    }
  }

  &__input {
    display: inline-block;
    position: relative;
    width: 100%;

    input {
      appearance: none;
      background-color: var(--input-bg-color, #{$white});
      border: 0.2rem solid #f1f1f1;
      border-radius: 0.2rem;
      color: var(--input-font-color, #{$black});
      font-family: $font-family;
      font-size: 1.6rem;
      height: 4rem;
      margin: 0;
      outline: none;
      padding: 0.5rem 1rem;
      width: 100%;

      @include placeholder {
        color: var(--input-placeholder-color , $grey);
      }

      @include min-tablet {
        font-size: 2rem;
        height: 6rem;
        padding: 0.5rem 1.5rem;
      }

      /* eslint-disable selector-no-qualifying-type */
      &[type='date'].unstyled {
        &::-webkit-inner-spin-button {
          appearance: none;
          display: none;
        }

        &::-webkit-calendar-picker-indicator {
          line-height: 6rem;
        }
      }
      /* eslint-disable selector-no-qualifying-type */

      // Input number remove arrows
      &[type='number'] {
        appearance: textfield;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          appearance: none;
          margin: 0;
        }
      }

      &.vrm {
        font-family: $font-family-vrm;
        @include placeholder {
          color: #dfdfdf;
        }
      }

      &.vrm--big {
        font-size: 3.2rem;
        height: 5rem;
        line-height: 5rem;
        padding: 0 2rem;

        @include min-tablet {
          font-size: 4.6rem;
          height: 6.5rem;
          line-height: 6.5rem;
          padding: 0 2.5rem;
          width: 100%;
        }
      }

      &:disabled {
        background-color: $grey-light;
      }

      &:focus {
        border-color: $blue;
      }

      .form__field--invalid & {
        border-color: $red;
        color: $red;
      }
    }

    select {
      appearance: none;
      background-color: var(--input-bg-color, $white);
      border: 0.2rem solid #f1f1f1;

      border-radius: 0.2rem;
      color: var(--input-font-color, #{$black});
      font-family: $font-family;
      font-size: 1.6rem;
      font-weight: bold;
      height: 4rem;
      margin: 0;
      outline: none;
      padding: 0.5rem 1rem;
      width: 100%;

      @include min-tablet {
        font-size: 2rem;
        height: 6rem;
        padding: 0.5rem 1.5rem;
      }

      &:disabled {
        background-color: $grey-light;
      }

      &:focus {
        border-color: $blue;
      }

      .form__field--invalid & {
        border-color: $red;
      }
    }

    textarea {
      appearance: none;
      background-color: $white;
      border: 0.2rem solid #f1f1f1;
      border-radius: 0.2rem;
      font-family: $font-family;
      font-size: 1.6rem;
      max-width: 100%;
      outline: none;
      padding: 1rem 1rem 0.5rem;
      width: 100%;

      @include min-tablet {
        font-size: 2rem;
        padding: 1rem 1.5rem;
      }

      &:disabled {
        background-color: $grey-light;
      }

      &:focus {
        border-color: $blue;
      }

      @include placeholder {
        color: $grey;
      }

      .form__field--invalid & {
        border-color: $red;
        color: $red;
      }
    }

    .form__field--small &,
    .form--small & {
      input,
      select {
        @include min-tablet {
          border-radius: 0.2rem;
          font-size: 1.6rem;
          height: 4rem;
        }
      }

      textarea {
        @include min-tablet {
          font-size: 1.6rem;
        }
      }
    }
  }

  &__hint,
  &__error {
    font-size: 1.3rem;
    position: relative;
    text-align: right;
    top: 0.3rem;
    width: 100%;
  }

  &__hint {
    color: var(--hint-color, #{$grey-darkest});
    display: block;

    .form__field--invalid & {
      display: none;
    }
  }

  &__error {
    color: $red;
    display: none;

    span {
      display: none;

      &:first-child {
        display: inline; //displaying only the first error
      }
    }

    .form__field--invalid & {
      display: block;
    }
  }
}

.choices__chevron {
  color: $orange;
  pointer-events: none;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;

  @include min-tablet {
    right: 1.5rem;
  }

  .form__field.loading & {
    display: none;
  }
}
