@import '../helpers/variables';
@import '../mixins';

// Popup
.notifications {
  background: $white;
  border-radius: 5px;
  display: none;
  flex-direction: column;
  font-family: $font-family-copy;
  left: 0;
  position: fixed;
  top: 100px;
  width: 100%;
  z-index: 10;

  @include min-tablet {
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
    height: auto;
    left: auto;
    max-width: 375px;
    position: absolute;
    right: 75px;
    top: 60px;
    width: 375px;
  }

  &::after,
  &::before {
    border: solid transparent;
    bottom: 100%;
    content: ' ';
    height: 0;
    left: 75%;
    pointer-events: none;
    position: absolute;
    width: 0;
  }

  &::after {
    border-color: rgba(238, 238, 238, 0);
    border-bottom-color: $navy-dark;
    border-width: 10px;
    margin-left: -10px;
  }

  &::before {
    border-color: rgba(204, 204, 204, 0);
    border-bottom-color: $navy-dark;
    border-width: 11px;
    margin-left: -11px;
  }

  &__close {
    color: $white;
    cursor: pointer;
    fill: $white;
    font-weight: bold;
    position: absolute;
    right: 12px;
    top: 7px;
  }

  &--active {
    display: flex;
  }

  &__title {
    background-color: $navy-dark;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 10px 10px 10px 20px;
  }
}

.notification {
  align-items: center;
  background-color: $white;
  border-bottom: 1px solid $grey-pale;
  color: $grey-darkest;
  display: flex;
  flex-wrap: nowrap;
  font-size: $font-small;
  justify-content: space-between;
  padding: 20px;

  &__message {
    flex-basis: 80%;
  }

  &__indicator {
    background-color: $green;
    border-radius: 5px;
    color: $green;
    cursor: pointer;
    height: 10px;
    overflow: hidden;
    visibility: hidden;
    width: 10px;

    &--active {
      visibility: visible;
    }
  }

  &:hover {
    background-color: darken($white, 5%);
  }
}

.scrollable {
  max-height: 513px;
  overflow-y: auto;
}
