@font-face {
    font-family: 'fontello';
    font-display: swap;
    src: url('../font/fontello.eot?20654262');
    src: url('../font/fontello.eot?20654262#iefix') format('embedded-opentype'),
    url('../font/fontello.woff2?20654262') format('woff2'),
    url('../font/fontello.woff?20654262') format('woff'),
    url('../font/fontello.ttf?20654262') format('truetype'),
    url('../font/fontello.svg?20654262#fontello') format('svg');
    font-weight: normal;
    font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?20654262#fontello') format('svg');
  }
}
*/
[class^="fi-"]:before, [class*=" fi-"]:before {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: never;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: .2em;

    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.fi-location:before { content: '\e800'; } /* '' */
.fi-picture:before { content: '\e801'; } /* '' */
.fi-down-open:before { content: '\e802'; } /* '' */
.fi-up:before { content: '\e803'; } /* '' */
.fi-user:before { content: '\e804'; } /* '' */
.fi-clock:before { content: '\e805'; } /* '' */
.fi-ok:before { content: '\e806'; } /* '' */
.fi-left-open:before { content: '\e807'; } /* '' */
.fi-star:before { content: '\e808'; } /* '' */
.fi-star-empty:before { content: '\e809'; } /* '' */
.fi-search:before { content: '\e80a'; } /* '' */
.fi-twitter:before { content: '\e80b'; } /* '' */
.fi-linkedin:before { content: '\e80c'; } /* '' */
.fi-facebook:before { content: '\e80d'; } /* '' */
.fi-instagram:before { content: '\e80e'; } /* '' */
.fi-home:before { content: '\e80f'; } /* '' */
.fi-cancel:before { content: '\e810'; } /* '' */
.fi-right-open:before { content: '\e811'; } /* '' */
.fi-up-open:before { content: '\e812'; } /* '' */
.fi-cog:before { content: '\e813'; } /* '' */
.fi-list:before { content: '\e814'; } /* '' */
.fi-lock:before { content: '\e815'; } /* '' */
.fi-info-circled:before { content: '\e816'; } /* '' */
.fi-pencil:before { content: '\e817'; } /* '' */
.fi-arrows-cw:before { content: '\e818'; } /* '' */
.fi-plus-circled:before { content: '\e819'; } /* '' */
.fi-attention-circled:before { content: '\e81a'; } /* '' */
.fi-attention:before { content: '\e81b'; } /* '' */
.fi-light-up:before { content: '\e81c'; } /* '' */
.fi-email:before { content: '\e81d'; } /* '' */
.fi-link-ext:before { content: '\f08e'; } /* '' */
.fi-phone:before { content: '\f098'; } /* '' */
.fi-menu:before { content: '\f0c9'; } /* '' */
.fi-gplus:before { content: '\f0d5'; } /* '' */
.fi-bell:before { content: '\f0f3'; } /* '' */
.fi-angle-left:before { content: '\f104'; } /* '' */
.fi-angle-right:before { content: '\f105'; } /* '' */
.fi-angle-up:before { content: '\f106'; } /* '' */
.fi-angle-down:before { content: '\f107'; } /* '' */
.fi-pound:before { content: '\f154'; } /* '' */
.fi-filter:before { content: '\f1de'; } /* '' */
.fi-share:before { content: '\f1e0'; } /* '' */
.fi-whatsapp:before { content: '\f232'; } /* '' */
.fi-question-circle-o:before { content: '\f29c'; } /* '' */
.fi-comment:before { content: '\f4ac'; } /* '' */
