@import '../../mixins';

/* Default */
.top-advert {
  max-width: 100%;
  overflow: hidden;
  text-align: left;

  > div:not([style*='display: none;']) {
    margin-bottom: 20px;
  }

  @include min-desktop {
    text-align: center;
  }
}

.sky {
  left: -180px;
  position: absolute;

  &__holder {
    position: relative;
  }
}

.mpu,
.bpfMpu {
  height: 250px;
  margin: 0 auto 15px;
  overflow: hidden;
  text-align: center;
  width: 300px;
}

.dmpu,
.bpfDmpu {
  height: 600px;
  margin: 0 auto 15px;
  overflow: hidden;
  text-align: center;
  width: 300px;
}

.bpfDmpu {
  position: sticky;
  top: 0;
}

.billboard {
  height: 250px;
  margin: 0 auto;
  width: 970px;
}

.leaderboard {
  height: 90px;
  margin: 0 auto;
  width: 728px;
}

.mobile-leader {
  bottom: $mobile-leaderboard-bottom;
  position: fixed;
  text-align: center;
  transform: translateZ(0);
  width: 100%;
  z-index: 100;
}

$debug: false;
@if $debug {
  .top-advert > div > div,
  .sky > div > div,
  .mpu > div > div,
  .dmpu > div > div,
  .mobile-leader > div > div {
    background-color: $red;
    margin: 0 auto;
  }
}
