@import '../helpers/variables';
@import '../mixins';

.top-header {
  background-color: $navy-dark;
  color: $white;
  display: flex;
  justify-content: flex-end;
  padding: 5px 30px;

  a {
    color: $white;
    font-family: $font-family;
    font-size: 14px;
    text-decoration: none;

    + a {
      margin-left: 20px;
    }
  }
}

.header {
  align-items: center;
  background: $white;
  color: $black;
  display: flex;
  min-height: 65px;
  padding: 0 15px;
  position: relative;
  transition: transform 0.25s;
  width: 100%;

  @include max-desktop {
    justify-content: space-between;
  }

  @include min-tablet {
    padding: 0 10px 0 30px;
  }

  &__logo {
    display: block;
    position: relative;
    width: 105px;
  }

  &__nav {
    display: flex;

    a {
      color: $grey-darkest;
      font-family: $font-family;
      font-weight: bold;
    }
  }

  &__nav-item {
    align-items: center;
    border-bottom: 3px solid transparent;
    border-top: 3px solid transparent;
    display: flex;
    height: 65px;

    + .header__nav-item {
      margin-left: 40px;
    }

    .fi-angle-down {
      position: absolute;
      transition: 0.25s linear;
    }

    &:hover {
      border-bottom: 3px solid $blue;

      .megamenu {
        display: block;
      }

      .fi-angle-down {
        transform: rotate(180deg);
      }
    }
  }

  &__right {
    align-items: center;
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
  }

  .bell.bell--desktop,
  &__search,
  &__garage-icon,
  &__menu {
    align-items: center;
    color: $navy-dark;
    cursor: pointer;
    display: flex;
    font-size: 25px;
    height: 44px;
    justify-content: center;
    position: relative;
    width: 44px;

    svg {
      fill: $navy-dark;
      height: 20px;
    }
  }

  .bell--has-unread::after {
    animation: toggleOpacity 2s linear infinite;
    background-color: $red;
    border-radius: 50%;
    content: '';
    height: 6px;
    position: absolute;
    right: 14px;
    top: 14px;
    width: 6px;
  }

  &__garage {
    align-items: center;
    border-left: 1px solid $grey;
    color: $navy-dark;
    display: flex;
    font-family: $font-family;
    font-size: 16px;
    font-weight: bold;
    height: 44px;
    justify-content: center;
    padding: 0 10px;
    text-decoration: none;
    white-space: nowrap;

    svg {
      fill: $navy-dark;
      height: 20px;
      margin-right: 10px;
    }
  }
}

.megamenu {
  background: linear-gradient(180deg, rgba(244, 244, 244, 1) 0%, rgba(255, 255, 255, 1) 16%), $white;
  display: none;
  left: 0;
  position: absolute;
  top: 65px;
  width: 100%;
  z-index: 500;

  &__bg {
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &--electric {
    .megamenu__bg {
      background-image: url('../img/menu/menu_tesla.png');
    }
  }

  &--van {
    .megamenu__bg {
      background-image: url('../img/menu/menu_van.png');
    }
  }

  &__links {
    margin: 0 auto;
    max-width: 80%;
    width: 100%;

    @include max-large {
      max-width: 90%;
    }

    li {
      border-bottom: 1px solid $grey;

      a {
        display: inline-block;
        padding: 10px 0;
        width: 100%;

        .fi-angle-right {
          color: $orange;
          float: right;
        }
      }
    }
  }
}

.bell__badge {
  background-color: #ff5959;
  border-radius: 10px;
  color: $white;
  font-family: $font-family-copy;
  font-size: 10px;
  font-weight: bold;
  height: 18px;
  left: 16px;
  line-height: 20px;
  position: absolute;
  top: -12px;
  width: 18px;

  @include max-mobile {
    right: 50px;
  }
}


@keyframes toggleOpacity {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

