@import '../helpers/variables';
@import '../mixins';

.gmodal {
  background-color: $white;
  border-radius: 10px;
  color: $navy-dark;
}

.dark-theme {
  --modal-bg: #{$navy-dark};
  --modal-color: #{$white};
}

.modal-animation-enter-active,
.modal-animation-leave-active {
  transition: opacity 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02);
}

.modal-animation-enter-from,
.modal-animation-leave-to {
  opacity: 0;
}

.modal-animation-inner-enter-active {
  transition: all 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02) 0.15s;
}

.modal-animation-inner-leave-active {
  transition: all 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02);
}

.modal-animation-inner-enter-from {
  opacity: 0;
  transform: scale(0.8);
}

.modal-animation-inner-leave-to {
  transform: scale(0.8);
}

.modal {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.75);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  left: 0;
  opacity: 1;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 999;

  &__inner {
    background-color: var(--modal-bg, #{$white});
    border-radius: 1rem;
    color: var(--modal-color, #{$navy-dark});
    max-height: 90vh;
    overflow-y: auto;
    position: relative;
    width: 80%;
  }

  &__close {
    color: $white;
    text-align: right;
    width: 80%;
  }

  &__lock {
    overflow: hidden;
  }
}
